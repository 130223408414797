import { useDispatch, useSelector } from "react-redux";

import { HideDialog } from "../store/modal/modal.actions";

import { modalState } from "../store/modal/modal.selectors";
import { navigate } from "@reach/router";

const success = require("./../images/success.svg");
const cross = require("./../images/icon/cross.svg");


const Dialog = ({ action }: { action?: string }) => {

  const dispatch = useDispatch();

  const { text, point } = useSelector(
    modalState.getAllState
  );

  const textPoint = point <= 1 ? 'point' : 'points';
  return (
    <div className="popup-assessment">
      <div className="popup-assessment__step">
        <div className="popup-assessment__head">
          <p className="popup-assessment__head__headline">
            Successfully {action == 'edit' ? 'Updated...' : 'Created...'}
          </p>
        </div>
        <div className="popup-assessment__body">
          <img src={success} alt="" className="popup-assessment__body__image" />
          <p className="popup-assessment__body__text">
            You have {action == 'edit' ? 'updated' : 'created'} a {text} with a passing score of {point} {textPoint}.
          </p>
        </div>
        <div className="popup-assessment__bottom center">
          <button
            className="popup-assessment__bottom__publish button--filled"
            onClick={() => {
              dispatch(HideDialog(false))
              if (localStorage.getItem("prevRoute") === "/recruitment/position-workflow/create") {
                localStorage.setItem("prevRoute", "")
                navigate("/recruitment/position-workflow/create");
              } else {
                navigate("/assessment/domain-assessment")
              }
            }
            }
          >
            Got it!
          </button>
        </div>
      </div>
    </div>
  );
}
export default Dialog;
