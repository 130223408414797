import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";


import { domainSelectors } from "../store/domain/domain.selectors";
import Questions from "./Questions";
import ExistingDomain from "./ExistingDomain";
import { SetStep, AddQuestion, ClearQuestion } from "../store/domain/domain.actions";
import { ClearData, SetData, SetScore } from "../store/existingDomain/existingDomain.actions";
import { existingDomainState } from "../store/existingDomain/existingDomain.selectors";
import { uuidv4 } from "../config/utils";
import { navigate } from "@reach/router";
import { getEnv } from "@urecruits/api";
const {API_ASSESSMENT}=getEnv()

import fetchData from "../hook/fetchData";
import { useHasPermission, AuthGuard } from '@ucrecruits/globalstyle/src/ucrecruits-globalstyle';

const stepOne = require("./../images/circleFill.svg");
const stepTwo = require("./../images/circleEmpty.svg");
const stepThree = require("./../images/stepTwo.svg");
const plus = require("./../images/icon/plus_ic.svg");
const cross = require("./../images/icon/cross.svg");
const line = require("./../images/line.svg");
const secondLine = require("./../images/secondLine.svg");


const StepTwo = () => {

    const {checkUserPermission} = useHasPermission()
    const dispatch = useDispatch();
    const { questions,editAction } = useSelector(domainSelectors.getAllState);

    const backStep = () => {
        dispatch(SetStep(1))
    }

    const nextStep = () => {
        const value = allScore;
        dispatch(SetScore(value));
        dispatch(SetStep(3))
    }

    const { addData } = useSelector(
        existingDomainState.getAllState
    );

    const score = questions.reduce((previousValue, currentValue) => previousValue + currentValue.score, 0);
    const allAddDataQuestion = addData.length && addData.map(item => {
        return (
            item.questions
        )
    });

    const scoreAddData = allAddDataQuestion ? allAddDataQuestion.map(item => {
        return item.reduce((previousValue, currentValue) => {
            return previousValue + currentValue.score
        }, 0);
    }) : 0;

    const filterAddData = Array.isArray(scoreAddData) ? scoreAddData[0] : 0;

    const allScore = score + filterAddData;
    const findScore = questions && questions.find(item => item.score === 0);
    const findName = questions && questions.some(item => item.name === "");
    const findOption = questions && questions.some((item) => {
        if(item.type !== "text"){
            return item?.answers?.some(dd => dd.name === "")
        }
    });    
    
    const editField = questions && questions.find(item => item.isEditPoint === true);
    const unlock = !findScore && !editField && !findName && !findOption? '' : 'disable';

    const handleAddQuestion = () => {
        const questionData = {
            id: uuidv4(),
            isEditPoint: false,
            name: '1. How much experience do you have in systems analysis?',
            type: 'single',
            answers: [{ "id": uuidv4(), "isCorrect": false, "score": 0, "name": "1 year" }, { "id": uuidv4(), "isCorrect": false, "score": 0, "name": "1-3 years" }],
            score: 0,
        };
        const data = [{
            questionData
        }]
        dispatch(AddQuestion(data))
    }

    const handleCancel=()=>{
        dispatch(ClearData(0));
        dispatch(ClearQuestion(''));
    }

    useEffect(() => {
        checkUserPermission('assessment','view') && fetchData(
            `${API_ASSESSMENT}/api/domain-questions/all?excludedId=${editAction?.id ||''}`,
            SetData
        ).then((res) => res.data)
            .then((res) => {
                const updateList = res.map((element) => {
                    return element.questions.map(item => {
                        const parseAnswers = item.answers && Array.isArray(item.answers) ? item.answers : item.type === 'text' ? item.answers : JSON.parse(item.answers);
                        return {
                            ...item,
                            answers: parseAnswers
                        }
                    })
                })
                const objCopy = [...res];
                const filterAnswer = objCopy.map((item, index) => {
                    const questionList = updateList[index]
                    return {
                        ...item,
                        questions: questionList
                    }

                })

                return dispatch(SetData(filterAnswer))
            });
    }, []);

    return (
        <div className="domain-modal">
            <div
                className="domain-modal__container"
                onClick={(e) => e.stopPropagation()}
            >
                <div className='domain-modal__head__wrapper'>
                    <div className="domain-modal__head">
                        <h1 className="domain-modal__head__title">
                        {editAction?.action==="edit" ? "Edit" : "Add New"} Domain Assessment
                        </h1>
                        <button
                            className="domain-modal__head__cross-ico"
                            onClick={() =>{
                                handleCancel()
                                navigate("/assessment/domain-assessment")
                            } 
                        }
                        >
                            <img src={cross} alt="cross-icon" />
                        </button>
                    </div>
                    <div className='domain-modal__circle__wrapper'>
                        <img className='domain-modal__circle' src={stepOne} alt="circle" />
                        <img className='domain-modal__line' src={line} alt="line" />
                        <img className='domain-modal__circle' src={stepTwo} alt="circle" />
                        <img src={secondLine} alt="line" />
                        <img className='domain-modal__circle' src={stepThree} alt="circle" />
                    </div>
                    <div className='domain-modal__subtitle__wrapper'>
                        <span className='domain-modal__subtitle'>
                            Assessment info
                        </span>
                        <span className='domain-modal__subtitle not-active mg-left'>
                            Questions
                        </span>
                        <span className='domain-modal__subtitle not-active'>
                            Time & Score
                        </span>
                    </div>
                </div>
                <div className="domain-modal__container__wrapper">
                    <div className="domain-modal__select mgTop">
                        {
                            questions && questions.map((item, index) => {
                                return (<Questions
                                    id={item.id}
                                    index={index}
                                    key={index}
                                    name={item.name}
                                    score={item.score}
                                    type={item.type}
                                    isEditPoint={item.isEditPoint}
                                    length={questions?.length}
                                />)
                            })
                        }
                    </div>
                    <button className="domain-modal__assessmentButton" onClick={() => handleAddQuestion()}>
                        <img className='domain-modal__plusIcon' src={plus} alt="cross-icon" />  One more question
                    </button>
                    <AuthGuard module='assessment' permission='view'>
                    <ExistingDomain />
                    </AuthGuard>
                </div>

               <div style={{width:"100%"}}>
                    {
                       (findOption || findName ) && <div style={{fontSize:"13px",color:"red"}}>Question name and option field is required and cannot be empty</div> 
                    }
                    <div className="domain-modal__footer js-sb">
                   
                    <div>
                        <button
                            className="domain-modal__footer__back"
                            onClick={() => backStep()}
                        >
                            Back
                        </button>
                    </div>
                    <div
                        className="domain-modal__footer__container"
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                        }}
                    >
                        <button
                            className="domain-modal__footer__container__cancel"
                            onClick={() =>{
                                if(localStorage.getItem("prevRoute")==="/recruitment/position-workflow/create")
                                { 
                                  localStorage.setItem("prevRoute","")
                                  handleCancel()
                                  navigate("/recruitment/position-workflow/create");
                                }else{
                                    handleCancel()
                                    navigate("/assessment/domain-assessment");
                                }
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            disabled={unlock === 'disable' ? true : false}
                            className={`domain-modal__footer__container__save ${unlock}`}
                            onClick={() => nextStep()}
                        >
                            Next
                        </button>
                    </div>
                    </div>
               </div>
            </div>
        </div >
    );
}

export default StepTwo;
